import * as types from '../actions/actionTypes'; // eslint-disable-line
import initialState from './initialState';

export default function StudioCReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_STUDIOC_CONFIGURATION_MODULES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                allInProgress: false,
            };
        case types.GET_PROJECT_VERSION_MENUITEMS_PENDING:
            return {
                ...state,
                allInProgress: true,
            };
        case types.GET_PROJECT_VERSION_MENUITEMS_FAILED:
            return {
                ...state,
                error: action.error,
                data: [],
                allInProgress: false,
            };
        default:
            return state;
    }
}
